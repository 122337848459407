<script setup>
import { Vue3Lottie } from 'vue3-lottie';
import { storeToRefs } from 'pinia';

import useUserStore from '@/stores/user';
import hashyLoader from '../assets/lottie/hashyAnimation.json';

const {
  isLoadingUser,
} = storeToRefs(useUserStore());

</script>

<template>
  <Transition name="fade">
    <div class="page-loader flex flex-column" v-if="isLoadingUser" data-entity="app-pageLoader">
      <Vue3Lottie :animationData="hashyLoader" :height="150" :width="150" />
      <h3>Something amazing awaits you...</h3>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
.page-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: white;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  color: #333;

  .dark-theme & {
    background-color: var(--dark-color);
    color: white;
  }
}
</style>
