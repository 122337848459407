import { defineStore, storeToRefs } from 'pinia';
import { computed } from 'vue';

import useUserStore from '@/stores/user';

export default defineStore('permissions', () => {
  const userStore = useUserStore();
  const { planPermissionData } = storeToRefs(userStore);

  const hasShoppable = computed(() => planPermissionData.value?.shoppable ?? false);

  const hasShoppableAmazon = computed(() => planPermissionData.value?.shoppable_amazon ?? false);

  const hasSportradar = computed(() => {
    const planPermissionDataValue = planPermissionData.value?.sportradar_element ?? false;
    return (Array.isArray(planPermissionDataValue) && planPermissionDataValue.length > 0);
  });

  const hasCustomFonts = computed(() => planPermissionData.value?.custom_fonts ?? false);

  const hasEmbedAnalytics = computed(() => planPermissionData.value?.embed_analytics ?? false);

  return {
    hasShoppable,
    hasShoppableAmazon,
    hasSportradar,
    hasCustomFonts,
    hasEmbedAnalytics,
  };
});
