<script setup>
import {
  watch,
} from 'vue';

import { storeToRefs } from 'pinia';

import Toast from 'primevue/toast';
import DynamicDialog from 'primevue/dynamicdialog';
import ConfirmDialog from 'primevue/confirmdialog';

import useThemeSwitcher from '@/composables/useThemeSwitcher';

import useAuthStore from '@/stores/auth';

const { currentTeamId } = storeToRefs(useAuthStore());

const { themePreference, applyTheme } = useThemeSwitcher();

watch(themePreference, (theme) => applyTheme(theme));
</script>

<template>
  <RouterView :key="currentTeamId" />
  <Toast />
  <DynamicDialog />
  <ConfirmDialog />
</template>

<style>
.page {
  margin:0;
}
</style>
